<template>
  <div>
    <!-- <Navbar /> -->
    <ErrorModal
      id="counter-error"
      @errorAction="errorHandler"
      :message="'Due to an error, your submition could not be uploaded to the server. Please reload the page and try again.'"
      :errorDetails="this.$store.state.errors.counterError.errorData"
    />
    <div class="ui equal width doubling stackable grid">
      <div
        class="twelve wide column"
        id="counter_container"
        style="display: flex; flex-direction: column"
      >
        <div
          class="ui fluid card"
          id="table-card"
          :class="{
            'double fast loading blue': stopCounterLoader || historyLoader,
          }"
        >
          <div class="content" style="padding: 0">
            <CounterTable />
          </div>
        </div>
      </div>
      <div class="column">
        <div id="logged_users">
          <div class="ui raised segment" id="logged_users_header">
            <div style="flex: 1">{{ historyLoaderText }}</div>
            <div
              v-if="historyLoader"
              class="ui inverted loading icon button"
              id="logged_users_loader"
            ></div>
          </div>
          <div v-if="workAndBrakeState.length > 0">
            <div
              style="display: flex; flex-direction: column"
              class="ui segment"
              :class="{
                'logged-working': each.workStage === 'Work',
                'logged-break': each.workStage === 'Break',
                'logged-other': each.workStage === 'Other',
              }"
              v-for="each in workAndBrakeState"
              :key="each"
            >
              <div v-if="each.workStage != 'Other'">
                <span class="ui small text" style="float: right"
                  >{{ each.workStage }} ({{ each.remote === 1 ? 'Remote' : 'Office' }})</span
                >
              </div>
              <div v-else>
                <span class="ui small text" style="float: right">{{
                  each.additionalInfo
                }}</span>
              </div>
              <div style="display: flex; justify-content: space-between">
                <div>Project: {{ each.projectName }}</div>
                <div>Started: {{ each.startTime }}</div>
              </div>

              <div style="display: flex; justify-content: space-between">
                <div>Campaign: {{ each.campaignName }}</div>
                <div>Stopped: {{ each.stopTime }}</div>
              </div>
              <div>
                Length: {{ each.displayStageDuration.hours }}:{{
                  each.displayStageDuration.minutes
                }}:{{ each.displayStageDuration.seconds }}
              </div>
            </div>
          </div>
          <div v-else>
            <div class="ui tertiary inverted red segment">
              <span class="ui normal text"
                >There is currently no work history for today!</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// const { Duration } = require("luxon");
// const { DateTime } = require("luxon");

// import Navbar from "../models/Navbar.vue";
// import SelectProject from "./SelectProject.vue";
// import TimeDisplay from "./display/TimeDisplay.vue";
// import TimerButtons from "./display/TimerButtons.vue";
// import ExtraBreak from "./ExtraBreak.vue";
import ErrorModal from "../models/modals/ErrorModal.vue";
import CounterTable from "./CounterTable.vue";

export default {
  data() {
    return {
      showConfirmChangeProject: false,
      loading: true,
      error: false,
    };
  },
  components: {
    // SelectProject,
    // Navbar,
    // TimeDisplay,
    // TimerButtons,
    // ExtraBreak,
    ErrorModal,
    CounterTable,
  },
  created() {
    this.$watch("checkErrors", (newError) => {
      if (newError) {
        $("#counter-error")
          .modal({
            class: "mini inverted",
            closable: false,
          })
          .modal("show");
      }
    });
  },
  // mounted() {
  //   this.$store.state.controlPanel = false;
  //   if (this.checkErrors) {
  //     $("#counter-error")
  //       .modal({
  //         class: "mini inverted",
  //         closable: false,
  //       })
  //       .modal("show");
  //   }
  // },
  // updated() {
  //   this.$store.state.controlPanel = false;
  //   if (this.checkErrors) {
  //     $("#counter-error")
  //       .modal({
  //         class: "mini inverted",
  //         closable: false,
  //       })
  //       .modal("show");
  //   }
  // },
  methods: {
    // async loadPage() {
    // 	this.loading = true;
    // 	try {
    // 		await this.$store.dispatch("getCurrentTimer");
    // 	} catch (error) {
    // 		this.error = error;
    // 	}
    // 	this.loading = false;
    // },
    errorHandler(value) {
      if (value) {
        // console.log("Retry");
        // this.$store.dispatch("counterHandler", {
        //   workStage: "Work",
        //   additionalInfo: "Test",
        // });
      } else {
        location.reload();
      }
      // this.$store.state.errors.counterError.connectionError = false;
    },
    changeProject() {
      this.showConfirmChangeProject = false;
      this.$store.dispatch("stopCounter");
    },
    confirmChangeProjectHandler() {
      this.showConfirmChangeProject = true;
    },
  },
  computed: {
    ...mapGetters([
      "showCurrentSeconds",
      "showCurrentMinutes",
      "showCurrentHours",
      "showWorkSeconds",
      "showWorkMinutes",
      "showWorkHours",
      "showBreakSeconds",
      "showBreakMinutes",
      "showBreakHours",
      "showOtherSeconds",
      "showOtherMinutes",
      "showOtherHours",
      "showTotalSeconds",
      "showTotalMinutes",
      "showTotalHours",
      "showTimeTable",
      "showWorkTimeTable",
      "showBreakTimeTable",
      "workAndBrakeState",
    ]),
    checkErrors() {
      if (this.$store.state.errors.counterError.connectionError) {
        return true;
      } else {
        return false;
      }
    },
    totalWorkDuration() {
      return this.$store.state.workTime.totalDuration;
    },
    totalBreakDuration() {
      return this.$store.state.breakTime.totalDuration;
    },
    extra() {
      return this.$store.state.extraBreak;
    },
    currentWorkStage() {
      return this.$store.state.workStage.currentlySelected;
    },
    uploadStatus() {
      return this.$store.state.uploadData.successful;
    },
    workStart() {
      // return DateTime(this.$store.state.workStart).toFormat("T");
      return this.$store.state.workStart;
    },
    extraBreakInfo() {
      return this.$store.state.extraBreak.additionalInfo;
    },
    stopCounterLoader() {
      return this.$store.state.loaders.stopCounterLoader;
    },
    historyLoader() {
      return this.$store.state.loaders.workHistoryLoader;
    },
    historyLoaderText() {
      if (this.historyLoader) {
        return "Fetching your history for today...";
      } else {
        return "Your work history for today:";
      }
    },
  },
};
</script>

<style scoped>
@media screen {
}

#counter_container {
  height: calc(100vh - 60px);
  margin-left: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#table-card {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none;
}

#logged_users {
  max-height: calc(100vh - 74px);
  margin-right: 14px;
  overflow: auto;
}

#logged_users_loader {
  box-shadow: none;
}

#logged_users_header {
  background: #3b5998;
  color: whitesmoke;
  font-weight: 700;
  position: sticky;
  top: 0;
  z-index: 1;
  display: flex;
  align-items: center;
}

.ui.segment.logged-working {
  background: #16ab39;
  color: whitesmoke;
  font-weight: 600;
}

.ui.segment.logged-break {
  background: #fbbd08;
  color: whitesmoke;
  font-weight: 600;
}

.ui.segment.logged-other {
  background: #a333c8;
  color: whitesmoke;
  font-weight: 600;
}

@media only screen and (max-width: 768px) {
  /* For tablets: */
  #counter_container {
    height: auto;
  }
  #logged_users {
    height: auto;
    margin-right: auto;
  }
}
</style>
