<template>
  <div id="power-bi">
    <iframe
      title="[VCC] - Timekeeper - MATKA"
      width="100%"
      height="100%"
      src="
https://app.powerbi.com/reportEmbed?reportId=1c44f56c-3e84-4895-9092-52f4856e42d9&autoAuth=true&ctid=01f56f7d-c298-4ea3-81dd-9a9acba3756b"
      frameborder="0"
      allowFullScreen="true"
    ></iframe>
  </div>
</template>

<script>
export default {
  mounted() {
    this.$store.state.controlPanel = true;
  },
};
</script>

<style scoped>
#power-bi {
  height: calc(100vh - 100px);
  overflow: auto;
}
</style>
